import React, { useState } from 'react'
import PropTypes from 'prop-types';
import {graphql} from 'gatsby'
import {Link} from 'gatsby'
import Img from "gatsby-image"
import {format, distanceInWords, differenceInDays} from 'date-fns'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../../lib/helpers'
import BlogPostPreviewList from '../../components/blog-post-preview-list'
import Container from '../../components/container'
import GraphQLErrorList from '../../components/graphql-error-list'
import SEO from '../../components/seo'
import Layout from '../../containers/layout'
import styled from 'styled-components'
import { ContainerFullWidth, ContainerMain, Backdrop, ContainerTwoCol, ContainerContent } from '../../containers'
import Hero from '../../components/hero'
import PortableText from '../../components/portableText'
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import ReactTooltip from 'react-tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import { MdHelpOutline, MdHelp, MdRateReview } from "react-icons/md";
import ReactSpeedometer from "react-d3-speedometer"

import NumberFormat from 'react-number-format';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, ResponsiveContainer, Legend
} from 'recharts';
import { DiscussionEmbed } from 'disqus-react'
import { Input } from '@material-ui/core';
import savingsRateImg from '../../assets/fire-savings-rate.png'
import Sidebar from '../../components/Sidebar'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query KeepingUpWithInflationCalcPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      metaImage {
        ...SanityImage
        alt
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
          fixed(width: 400) {
            ...GatsbySanityImageFixed
          }
        }
      }
    }
    posts: allSanityPost(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          authors{
            author{
              name
              id
            }
          }
          mainImage {
            asset{
              fluid {
                ...GatsbySanityImageFluid
              }
              fixed(width: 400) {
                ...GatsbySanityImageFixed
              }
            }
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const H2 = styled.h2`
  font-weight: 300;
`

const CalculatorGrid = styled(ContainerMain)`
  box-sizing: border-box;
  border: 1px solid ${ props => props.theme.theme.border.secondary};
  background-color: ${props => props.theme.theme.bg.primary};
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.05);
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
  align-items: start;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    padding: 24px 24px 0 24px;
    border: none;
    border-radius: 0px;
    margin: 0;
  }

  p {
    font-size: 0.9rem;
  }

  h3 {
    font-weight: 500;
  }
`

const GraphDesktop = styled.div`
  display: block;

  @media (max-width: 600px) {
    display: none;
  }
`

const GraphMobile = styled.div`
  display: none;

  @media (max-width: 600px) {
    display: block;
  }
`

const FlexInput = styled.div`
  margin: 0px 12px 12px 0;
`;

const InputGroup = styled.div`
  /* display: grid; */
  display: flex;
  flex-wrap: wrap;
  /* grid-template-columns: repeat(auto-fill, minmax(250px, 1fr) ) ; */
  /* grid-gap: 24px; */

  @media (max-width: 500px) {
    padding: 24px 12px 0 12px;
  }
`;

const ThemedTextarea = styled(TextField)`
  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.primary};
  }

  &.MuiFormControl-root {
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.formBorder.primary};
  }
  
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiInputBase-input {
    color: ${props => props.theme.theme.text.primary};
  }
`

const ThemedTextareaPrimary = styled(ThemedTextarea)`
  &.MuiFormControl-root {
    margin: 24px 0;
  }

  &:hover{
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.theme.colors.green};
    }
  }

  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.secondary};
    font-weight: 500;
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }
`

const InputItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 50px;
  grid-gap: 12px;
  align-items: center;
  margin: 0 0 0px 0;
`

const ThemedSlider = styled(Slider)`
  &.MuiSlider-root {
    color: ${props => props.theme.theme.colors.green};
  }
`

const Label = styled.h3`
  margin: 4px 0 0 0;
  font-size: 0.9rem;
  font-weight: 400;
  display: flex;

  svg {
    margin: 0 0 0 8px;
    height: 1.4rem;
  }
`


const CardText300 = styled.h3`
  margin: 0;
  font-size: 0.9rem;
`

const CardText400 = styled.h4`
  margin: 0;
  font-size: 0.9rem;
  font-weight: 400;
`

const TooltipPanel = styled.div`
  background-color: ${props => props.theme.theme.bg.secondary};
  box-shadow: 0 1px 2px 2px rgba(0,0,0,0.1);
  padding: 10px 10px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
`

const SavingsRateDisplay = styled.div`
  border: 1px solid ${props => props.theme.theme.colors.green};
  background-color: ${props => props.theme.theme.colors.transparentGreen};
  border-radius: 4px;
  padding: 16px;
  font-size: 1rem;
`

const SavingsRateDisplayRed = styled(SavingsRateDisplay)`
  background-color: ${props => props.theme.theme.colors.transparentRed};
  border: 1px solid ${props => props.theme.theme.colors.red};

`

const SliderGrid = styled.div`
  box-sizing: border-box;
  border: 0px solid ${ props => props.theme.theme.border.secondary};
  border-radius: 4px;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: 16px;
  align-items: start;
`

const SpeedometerContainer = styled.div`
  height: 300px;
  max-width: 500px;

  @media (max-width: 600px) {
    height: 200px;
  }

  .segment-value, .current-value {
    fill: ${props => props.theme.theme.text.primary} !important;
  }

  .pointer {
    fill: ${props => props.theme.theme.text.primary} !important;
  }
`

const Image = styled.img`
  width: 100%;
`

const AllocationGroup = styled.div`
  border-radius: 8px;
  padding: 8px 12px 12px 12px;
  margin: 0 12px 12px 0;

  h3 {
    /* text-transform: uppercase; */
    margin: 0 0 8px 0;
    font-weight: 500;
  }
`;

const ResultsPanel = styled(AllocationGroup)`
  /* background-color: ${(props) => props.theme.theme.colors.transparentGreen}; */
  /* border: 1px solid ${(props) => props.theme.theme.colors.green}; */
  padding: 12px;
  display: flex;
  /* grid-template-columns: 1fr; */
  flex-wrap: wrap;
  margin: 12px 0 0 0;

  .label {
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 8px 0 0;
  }

  .result-value {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 4px 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin: 0 6px;
  }

  h3 {
    /* color: ${(props) => props.theme.theme.colors.green}; */
    width: 100%;
  }

  @media (max-width: 500px) {
    margin: 0 12px 12px 0;
    display: flex;
    flex-wrap: wrap;
  }
`;

const ResultsPanelGreen = styled(ResultsPanel)`
  background-color: ${(props) => props.theme.theme.colors.transparentGreen};
  border: 1px solid ${(props) => props.theme.theme.colors.green};

  h3 {
    color: ${(props) => props.theme.theme.colors.green};
  }
`

const ResultsGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 4px 12px 4px 0;

  p {
    font-size: 1rem;
    margin: 8px 0;
  }

  @media (max-width: 500px) {
  }
`;



function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function NumberFormatPercentage(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      suffix="%"
    />
  );
}

NumberFormatPercentage.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const KeepingUpWithInflationCalc = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const posts = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  const [ monthlyIncome, setMonthlyIncome ] = useState(5000)
  const [ monthlySpending, setMonthlySpending ] = useState(3000)

  const [ inflationRate, setInflationRate ] = useState(10)
  const [ marginalIncomeTaxRate, setMarginalIncomeTaxRate ] = useState(40)


  const savingsRate =  (monthlyIncome - monthlySpending) / monthlyIncome

  const raiseToKeepUpWithInflation = 100 * ( (inflationRate/100) - ((inflationRate/100) * (savingsRate))) / (1 - (marginalIncomeTaxRate/100))

  const monthlySpendingAfterInflation = monthlySpending * (1 + (inflationRate/100))

  const monthlyIncomeAfterInflation = monthlyIncome * ( 1 + ( (raiseToKeepUpWithInflation/100) * ( 1 - (marginalIncomeTaxRate/100) ) ) )

  const savingsRateAfterInflation = ( monthlyIncomeAfterInflation - monthlySpendingAfterInflation ) / monthlyIncomeAfterInflation

  const incomeToMaintainInitialSavingsRate = monthlySpendingAfterInflation / ( 1 - savingsRate )

  const raiseToMaintainInitialSavingsRate = (( incomeToMaintainInitialSavingsRate - monthlyIncome ) / monthlyIncome) / ( 1 -  (marginalIncomeTaxRate/100) )

  const savingsRateAdjusted = savingsRate > 0 ? savingsRate : 0

  let Dialog

  if(isNaN(savingsRate)){
    Dialog = <SavingsRateDisplay>Please enter your monthly income and spending</SavingsRateDisplay>
  } else {
    if(savingsRate >= 0){
      Dialog =
      <div>


        <ResultsPanel>
            <ResultsGroup>
              <span className="label">Your savings rate: </span>
              <span className="result-value">
                <NumberFormat
                  displayType={"text"}
                  value={(savingsRate*100).toFixed(1)}
                  suffix="%"
                />
              </span>
            </ResultsGroup>

            <ResultsGroup>
              <span className="label">Your monthly savings: </span>
              <span className="result-value">
                <NumberFormat
                  displayType={"text"}
                  value={(monthlyIncome - monthlySpending).toFixed(0)}
                  thousandSeparator
                  prefix="$"
                />
              </span>
            </ResultsGroup>

            <ResultsGroup>
              <span className="label">Your monthly spending after inflation: </span>
              <span className="result-value">
                <NumberFormat
                  displayType={"text"}
                  value={(monthlySpending * ( 1 + (inflationRate / 100 ))).toFixed(0)}
                  thousandSeparator
                  prefix="$"
                />
              </span>
            </ResultsGroup>
        </ResultsPanel>

        <ResultsPanelGreen>

          <h3>To keep up with inflation</h3>

          <ResultsGroup>
            <span className="label">Raise needed to keep up with inflation:</span>
            <span className="result-value">
              <NumberFormat
                displayType={"text"}
                value={(raiseToKeepUpWithInflation).toFixed(1)}
                suffix="%"
              />
            </span>
          </ResultsGroup>

          <ResultsGroup>
            <span className="label">Monthly take-home pay: </span>
            <span className="result-value">
              <NumberFormat
                displayType={"text"}
                value={monthlyIncomeAfterInflation.toFixed(0)}
                thousandSeparator
                prefix="$"
              />
            </span>
          </ResultsGroup>

          <ResultsGroup>
            <span className="label">Monthly savings: </span>
            <span className="result-value">
              <NumberFormat
                displayType={"text"}
                value={ (monthlyIncomeAfterInflation - (monthlySpending*( 1 + (inflationRate/100) ) )).toFixed(0)}
                thousandSeparator
                prefix="$"
              />
            </span>
          </ResultsGroup>

          <ResultsGroup>
            <span className="label">Savings rate after {`${(raiseToKeepUpWithInflation).toFixed(1)}%`} raise:</span>
            <span className="result-value">
              <NumberFormat
                displayType={"text"}
                value={(savingsRateAfterInflation*100).toFixed(1)}
                suffix="%"
              />
            </span>
          </ResultsGroup>

        </ResultsPanelGreen>

        <ResultsPanelGreen>
          <h3>To maintain your initial savings rate of {(savingsRate*100).toFixed(1)}%</h3>

          <ResultsGroup>
            <span className="label">Raise needed to maintain initial savings rate:</span>
            <span className="result-value">
              <NumberFormat
                displayType={"text"}
                value={(raiseToMaintainInitialSavingsRate*100).toFixed(1)}
                suffix="%"
              />
            </span>
          </ResultsGroup>

            <ResultsGroup>
              <span className="label">Monthly take-home pay: </span>
              <span className="result-value">
                <NumberFormat
                  displayType={"text"}
                  value={incomeToMaintainInitialSavingsRate.toFixed(0)}
                  thousandSeparator
                  prefix="$"
                />
              </span>
            </ResultsGroup>

            <ResultsGroup>
              <span className="label">Monthly savings: </span>
              <span className="result-value">
                <NumberFormat
                  displayType={"text"}
                  value={ (incomeToMaintainInitialSavingsRate - monthlySpendingAfterInflation).toFixed(0)}
                  thousandSeparator
                  prefix="$"
                />
              </span>
            </ResultsGroup>
        </ResultsPanelGreen>
      </div>

    } else{
      Dialog = <SavingsRateDisplayRed>
        Yikes! Your spending exceeds your income.
        You need to lower your spending to spend less than you earn.
      </SavingsRateDisplayRed>
    }
  }

  console.log(savingsRateImg)

  return (
    <Layout>
      <SEO
        title={'Keeping Up With Inflation Calculator'}
        description={'Calculate how much of a salary raise you need to keep up with inflation'}
        keywords={site.keywords}
      />
      <ReactTooltip />
      <ContainerMain>
        <h1>Keeping Up With Inflation Calculator</h1>
        <H2>Calculate how much of a salary raise you need to keep up with inflation</H2>
      </ContainerMain>

      <CalculatorGrid>
        <form>
          <InputGroup>
            <FlexInput style={{ width: "250px" }}>
              <InputItem>
                <ThemedTextarea
                  id="outlined-name"
                  label="Annual Inflation Rate"
                  value={inflationRate}
                  onChange={event => setInflationRate(event.target.value)}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatPercentage,
                  }}
                />
                <MdHelp size="24px"
                  data-tip={`This is the annual inflation rate that you personally experience in your spending due to increases in cost of things like rent, food, and fuel costs.`}
                />
              </InputItem>
            </FlexInput>

            <FlexInput style={{ width: "275px" }}>
              <InputItem>
                <ThemedTextarea
                  id="outlined-name"
                  label="Marginal Income Tax Rate"
                  value={marginalIncomeTaxRate}
                  onChange={event => setMarginalIncomeTaxRate(event.target.value)}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatPercentage,
                  }}
                />
                <MdHelp size="24px"
                  data-tip={`This is the rate of income tax that you pay on every additional dollar that you earn as income.`}
                />
              </InputItem>
            </FlexInput>
          </InputGroup>

        <h3>Before inflation</h3>

        <InputGroup >
          <FlexInput style={{ width: "300px" }}>
            <InputItem>
              <ThemedTextarea
                id="outlined-name"
                label="Monthly take-home pay"
                value={monthlyIncome}
                onChange={event => setMonthlyIncome(event.target.value)}
                variant="outlined"
                fullWidth
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
              <MdHelp size="24px"
                data-tip={`This is your monthly take-home pay after all taxes, before getting a raise.
                This DOES include any contributions you make to retirement accounts like a 401k or HSA.`}
              />
            </InputItem>
          </FlexInput>

          <FlexInput style={{ width: "320px" }}>
            <InputItem>
              <ThemedTextarea
                id="outlined-name"
                label="Monthly spending"
                value={monthlySpending}
                onChange={event => setMonthlySpending(event.target.value)}
                variant="outlined"
                fullWidth
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
              <MdHelp size="24px"
                data-tip={`This is your total monthly spending before you experience price increases due to inflation.`}
              />
            </InputItem>
          </FlexInput>


        
        </InputGroup>

          {Dialog}

        </form>

      </CalculatorGrid>

      <ContainerTwoCol>
        <ContainerContent>
          <h2>Introduction</h2>
          <p>
            In recent years, we have seen dramatic increases in the cost of goods and service across the USA and the world, which is known as inflation. 
            With the cost of everything from gas to housing rising rapidly, "keeping up with inflation" by earning more income is essential to build your
            wealth. But how much of an increase in income do you need to keep pace with inflation? Or better yet, maintain the 
            same <a href="/tools/savings-rate-calculator" target="_blank">savings rate</a> that you
            had before the record inflation we have seen over the past year? I made this calculator to help you find out.

          </p>
          <p>
            This calculator was inspired by the article <a href="https://ofdollarsanddata.com/youve-been-thinking-about-inflation-all-wrong/" target="_blank">You’ve 
            Been Thinking About Inflation All Wrong</a> by Nick Maggiulli. Nick makes the point that keeping up with inflation is much more nuanced than 
            simply getting a raise that is equal to the Consumer Price Index (CPI). If you are a heavy saver, then you need less of a raise to cover an increase in
            spending than a person who is living paycheck-to-paycheck, which makes sense.
          </p>

          <p>
            However in his article, Nick neglected to consider the impact of income taxes on how much of a raise is required, as well as the idea of 
            maintaining the same savings rate. So to expand on Nick's concept, I built this calculator which adds some additional factors like 
            marginal income tax rate and maintaining the same savings rate that you had initially.
          </p>

          <h2>Using this calculator</h2>
          <p>
            This interactive calculator makes it easy to calculate how much of a raise you need to keep up with inflation and how this could impact your
            savings rate. Simply input your numbers and the calculator will automatically update.
          </p>

          <ul>
            <li>
              <strong>Annual inflation rate</strong> is the annual inflation rate that you personally experience in your spending due to increases in 
              cost of things like rent, food, and fuel costs.
            </li>
            <li>
              <strong>Marginal income tax rate</strong> is the rate of income tax that you pay on every additional dollar that you earn as income. You can check
              out <a href='https://smartasset.com/taxes/income-taxes' target='_blank'>Smart Asset's Income Tax Calculator</a> to determine your marginal income tax rate.
            </li>
            <li>
              <strong>Monthly take-home pay</strong> is your monthly take-home pay after paying all taxes, before you get a raise. This does include pre-tax
              contributions to things like a 401k or HSA.
            </li>
            <li>
              <strong>Monthly spending</strong> is your total monthly spending before you experience price increases due to inflation.
            </li>
          </ul>

          <p>
            The calculator then breaks down results into two scenarios: 1) <strong>keeping up with inflation</strong> and 
            2) <strong>maintaining your initial savings rate</strong>. <strong>Keeping up with inflation</strong> calculates
            how much of a raise you need to save the same amount in absolute terms as you did before inflation, 
            considering your marginal income tax on any raise you recieve. The <strong>maintaining your initial savings rate</strong> scenario goes
            a step further, calculating the raise you need to maintain the same savings rate as before inflation. Since your spending increases due to inflation, 
            you will need to save more money as well to maintain the same saving rate. 
            <strong> This is important because savings rate is the single factor that determines how quickly you can reach financial independence, so if your
            savings rate is continually diminished due to inflation, then your timeline to financial independence will become increasingly out-of-reach.</strong>
          </p>

          <h2>What is my Savings Rate?</h2>
          <p>
            Your personal savings rate is a measure of how much of your personal disposable income is saved rather than spent.
            It is the ratio of your personal savings divided by disposable income over a given period and is typically written as a percentage.
            Your disposable income should be used to calculate savings rate and this is your total income after all income taxes.
          </p>

          <p>
            The higher your savings rate, the stronger your personal financial situation.
            A savings rate of 0% means that you spend all of your income and save none of your income.
            On the other hand, a savings rate of 100% means that you save all of your income and spend none of your income.
          </p>

          <h2>How do I calculate my Savings Rate?</h2>
          <p>
            Savings Rate (SR) is defined as the ratio of savings divided by your income. Your savings over any period is your income - expenses.
            Thus your SR = (Income after tax - spending) / (Income after tax). To convert this SR to a percentage, multiply by 100.
          </p>

          <p>
            You can learn more about the importance of your savings rate by viewing my <a href="/tools/savings-rate-calculator" target="_blank">Savings Rate Calculator</a>.
          </p>

        </ContainerContent>
        <Sidebar />

      </ContainerTwoCol>
      <Backdrop>
        <ContainerMain>
          <DiscussionEmbed
              shortname={process.env.GATSBY_DISQUS_NAME}
              config={{
                  identifier: 'keeping-up-with-inflation-calculator'
              }}
          />
        </ContainerMain>
      </Backdrop>
    </Layout>
  )
}

export default KeepingUpWithInflationCalc
